import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  getAuth,
  onAuthStateChanged,
  sendEmailVerification,
} from "firebase/auth";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../firebase";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  addDoc,
} from "firebase/firestore";
import { Link, Navigate } from "react-router-dom";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { GrNext, GrPrevious } from "react-icons/gr";
import ReactMapGL, { Marker } from "react-map-gl";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StarRating from "../components/StartRating";
import moment from "moment";
import StarSelector from "../components/StarSelector";
import { MdDelete, MdEdit } from "react-icons/md";
import { deleteDoc } from "firebase/firestore";
import { TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IoShareOutline } from "react-icons/io5";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { FaCopy } from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { FaMessage } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import { FaPhone } from "react-icons/fa6";
import { Helmet } from "react-helmet";
import { FaAngleDown } from "react-icons/fa6";
import Bar from "../components/Bar";







export default function AgentProfile() {
  const auth = getAuth();
  const contactFormRef = useRef(null);
  const { t } = useTranslation();
  const { agentId } = useParams();
  const [agentInfo, setAgentInfo] = useState(null);
  const [agentListings, setAgentListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [memberSince, setMemberSince] = useState("");
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [contactFormData, setContactFormData] = useState({
    name: "",
    phoneNumber: "",
  });
  const [nameError, setNameError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [message, setMessage] = useState(t("agentMessageTemplate"));
  const [loadingUser, setLoadingUser] = useState(true);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const listingsPerPage = 3;
  const [viewport, setViewport] = useState({
    width: "100%",
    height: 400,
    latitude: 31.7917, // Latitude for Morocco
    longitude: -7.0926, // Longitude for Morocco
    zoom: 5, // Adjust zoom level as needed
  });
  const [saleListingsCoordinates, setSaleListingsCoordinates] = useState([]);
  const [rentListingsCoordinates, setRentListingsCoordinates] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [reviewFormData, setReviewFormData] = useState({
    userId: "", // Assuming you have user authentication
    rating: 0,
    comment: "",
  });
  const [ratingError, setRatingError] = useState(""); // State for rating error
  const [isAdmin, setIsAdmin] = useState(false); // New state to track if the user is an admin
  const [isVerified, setIsVerified] = useState(false);
  const [user, setUser] = useState(null);
  const [sendingVerification, setSendingVerification] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState("");
  const [reviewsToShow, setReviewsToShow] = useState(1);
  
  // Function to show more reviews
  const showMoreReviews = useCallback(() => {
    setReviewsToShow((prev) => prev + 2);
  }, []);

  useEffect(() => {
    let isMounted = true; // Track component mount status
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (isMounted) {
        if (user) {
          setUser(user);
          setIsVerified(user.emailVerified);
        } else {
          setUser(null);
          setIsVerified(false);
        }
      }
    });
    return () => {
      isMounted = false;
      unsubscribe();
    };
  }, [auth]);

  const handleResendVerification = useCallback(async () => {
    if (!user) return;
    setSendingVerification(true);
    setError("");
    try {
      await sendEmailVerification(user);
      setEmailSent(true);
    } catch (error) {
      setError(t("failedemail"));
    } finally {
      setSendingVerification(false);
    }
  }, [user, t]);

  const handleDeleteReview = useCallback(async (reviewId) => {
    if (window.confirm("Are you sure you want to delete?")) {
      try {
        await deleteDoc(doc(db, "agent_reviews", reviewId));
        setReviews((prevReviews) =>
          prevReviews.filter((review) => review.id !== reviewId)
        );
        toast.success("Successfully deleted review");
      } catch (error) {
        console.error("Error deleting review:", error);
        toast.error("An error occurred while deleting the review.");
      }
    }
  }, []);

  // Function to format review timestamp
  const formatReviewTimestamp = (timestamp) => {
    // Check if timestamp is a Firestore Timestamp object
    if (timestamp && timestamp.toDate) {
      // Convert Firestore Timestamp to JavaScript Date object
      const date = timestamp.toDate();
      // Return time ago using moment.js
      return moment(date).fromNow();
    } else if (timestamp instanceof Date) {
      // If timestamp is already a JavaScript Date object, return time ago directly
      return moment(timestamp).fromNow();
    } else {
      // Handle invalid timestamp format
      return "Invalid timestamp";
    }
  };

  const handlePrevPage = useCallback(() => {
    setCurrentPage((prevPage) => prevPage - 1);
  }, []);

  const handleNextPage = useCallback(() => {
    setCurrentPage((prevPage) => prevPage + 1);
  }, []);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    if (name === "name" || name === "phoneNumber") {
      setContactFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else if (name === "message") {
      setMessage(value);
    }
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const { name, phoneNumber, message } = contactFormData;
      const subject = `New Inquiry from ${name} on Beytty.com`;
      const emailBody = `Phone Number: ${phoneNumber}\n\nMessage: ${message}`;
      const mailtoLink = `mailto:${
        agentInfo.email
      }?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
        emailBody
      )}`;
      window.location.href = mailtoLink;
      setContactFormData({
        name: "",
        phoneNumber: "",
        message: "",
      });
    },
    [contactFormData, agentInfo, t]
  );

   const handleSendEmail = useCallback(() => {
     const { name, phoneNumber } = contactFormData;
     setPhoneNumberError("");
     setMessageError("");
     setNameError("");

     if (!phoneNumber || !phoneNumber.trim()) {
       setPhoneNumberError("Please enter your phone number.");
       return;
     }
     if (!name || !name.trim()) {
       setNameError("Please enter your name.");
       return;
     }
     if (!message || !message.trim()) {
       setMessageError("Please enter your message.");
       return;
     }

     window.location.href = `mailto:${agentInfo.email}?Subject=Beytty : ${t(
       "newinquiryfrom"
     )} ${name}&body=${message}%0D%0A%0D%0A${t("heresmypn")} ${phoneNumber}`;
   }, [contactFormData, agentInfo, t]);

   const handleSendWhatsApp = useCallback(() => {
     const { name, phoneNumber } = contactFormData;
     setPhoneNumberError("");
     setMessageError("");
     setNameError("");

     if (!phoneNumber || !phoneNumber.trim()) {
       setPhoneNumberError("Please enter your phone number.");
       return;
     }
     if (!name || !name.trim()) {
       setNameError("Please enter your name.");
       return;
     }
     if (!message || !message.trim()) {
       setMessageError("Please enter your message.");
       return;
     }

     const whatsappMessage = `${message}\n${t("heresmypn")} ${phoneNumber}`;
     const whatsappLink = `https://wa.me/${
       agentInfo.phoneNumber
     }?text=${encodeURIComponent(whatsappMessage)}`;
     window.location.href = whatsappLink;
   }, [contactFormData, agentInfo, t]);

  useEffect(() => {
    let isMounted = true;

    async function fetchUser() {
      try {
        const user = auth.currentUser;
        if (user) {
          const userRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(userRef);
          if (docSnap.exists() && isMounted) {
            const userData = docSnap.data();
            const { firstName, phoneNumber } = userData;
            setContactFormData({
              name: firstName,
              phoneNumber: phoneNumber,
            });
          } else {
            console.log("No such document!");
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        if (isMounted) {
          setLoadingUser(false);
        }
      }
    }

    fetchUser();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [auth.currentUser, db]);


  useEffect(() => {
    let isMounted = true; // Track whether the component is mounted

    const fetchAgentData = async () => {
      try {
        // Fetch agent data
        const agentDocRef = doc(db, "agents", agentId);
        const agentDocSnapshot = await getDoc(agentDocRef);

        if (agentDocSnapshot.exists()) {
          const agentData = agentDocSnapshot.data();
          if (isMounted) {
            setAgentInfo(agentData);
            const joinTimestamp = agentData.timestamp.toDate();
            const joinDate = joinTimestamp.toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            });
            setMemberSince(joinDate);
            setSelectedLanguages(agentData.selectedLanguages || ["--"]);

            const listingsRef = collection(db, "listings");
            const q = query(
              listingsRef,
              where("userRef", "==", agentId),
              where("status", "==", "approved")
            );
            const querySnap = await getDocs(q);
            let listings = [];
            let saleCoordinates = [];
            let rentCoordinates = [];
            querySnap.forEach((doc) => {
              const listingData = doc.data();
              listings.push({
                id: doc.id,
                data: listingData,
              });
              if (listingData.type === "sale") {
                saleCoordinates.push({
                  id: doc.id,
                  latitude: listingData.latitude,
                  longitude: listingData.longitude,
                });
              } else if (listingData.type === "rent") {
                rentCoordinates.push({
                  id: doc.id,
                  latitude: listingData.latitude,
                  longitude: listingData.longitude,
                });
              }
            });
            if (isMounted) {
              setAgentListings(listings);
              setSaleListingsCoordinates(saleCoordinates);
              setRentListingsCoordinates(rentCoordinates);
              setLoading(false);
            }
          }
        } else {
          // Fetch from admins collection if agent not found
          const adminDocRef = doc(db, "admins", agentId);
          const adminDocSnapshot = await getDoc(adminDocRef);

          if (adminDocSnapshot.exists()) {
            const adminData = adminDocSnapshot.data();
            if (isMounted) {
              setAgentInfo(adminData);
              setIsAdmin(true); // Set isAdmin to true
              const joinTimestamp = adminData.timestamp.toDate();
              const joinDate = joinTimestamp.toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              setMemberSince(joinDate);
              setSelectedLanguages(adminData.selectedLanguages || ["--"]);

              const listingsRef = collection(db, "listings");
              const q = query(
                listingsRef,
                where("userRef", "==", agentId),
                where("status", "==", "approved")
              );
              const querySnap = await getDocs(q);
              let listings = [];
              let saleCoordinates = [];
              let rentCoordinates = [];
              querySnap.forEach((doc) => {
                const listingData = doc.data();
                listings.push({
                  id: doc.id,
                  data: listingData,
                });
                if (listingData.type === "sale") {
                  saleCoordinates.push({
                    id: doc.id,
                    latitude: listingData.latitude,
                    longitude: listingData.longitude,
                  });
                } else if (listingData.type === "rent") {
                  rentCoordinates.push({
                    id: doc.id,
                    latitude: listingData.latitude,
                    longitude: listingData.longitude,
                  });
                }
              });
              if (isMounted) {
                setAgentListings(listings);
                setSaleListingsCoordinates(saleCoordinates);
                setRentListingsCoordinates(rentCoordinates);
                setLoading(false);
              }
            }
          }
        }
      } catch (error) {
        console.error("Error fetching agent data:", error);
      }
    };

    const fetchReviews = async () => {
      try {
        const reviewsRef = collection(db, "agent_reviews");
        const q = query(reviewsRef, where("agentId", "==", agentId));
        const querySnap = await getDocs(q);
        let reviewsData = [];
        querySnap.forEach((doc) => {
          reviewsData.push({ id: doc.id, data: doc.data() });
        });
        if (isMounted) {
          setReviews(reviewsData);
        }
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };

    fetchAgentData();
    fetchReviews();

    return () => {
      isMounted = false; // Cleanup function to set isMounted to false
    };
  }, [agentId]);


  const handleReviewInputChange = (e) => {
    const { name, value } = e.target;
    setReviewFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleReviewRatingChange = (value) => {
    setReviewFormData((prevData) => ({
      ...prevData,
      rating: value,
    }));
    setRatingError(""); // Clear the error when a rating is selected
  };

  const handleReviewSubmit = async (e) => {
    e.preventDefault();
    try {
      // Check if the rating is 0
      if (reviewFormData.rating === 0) {
        setRatingError("Please choose a rating");
        return;
      } else {
        setRatingError(""); // Clear the error if rating is valid
      }

      // Get user information from Firebase Authentication
      const user = auth.currentUser;
      if (!user) {
        // If user is not authenticated, display error message
        toast.error("You must be logged in to submit a review.");
        return;
      }

      // Check if the user exists in the users collection
      const userDoc = await getDoc(doc(db, "users", user.uid));
      if (!userDoc.exists()) {
        // If user does not exist in users collection, display error message
        toast.error("You are not authorized to submit a review.");
        return;
      }

      // Check if the user has already submitted a review for the agent
      const existingReview = reviews.find(
        (review) => review.data.userId === user.uid
      );
      if (existingReview) {
        // Display error toast if user has already submitted a review
        toast.error("You have already submitted a review for this agent.");
        return;
      }

      // Ensure user's first name is available
      const firstName = user.displayName;
      if (!firstName) {
        console.error("User's first name is not available.");
        return;
      }

      // Prepare review data with user's first name
      const reviewData = {
        ...reviewFormData,
        agentId,
        userId: user.uid,
        firstName: firstName,
        timestamp: new Date(), // Add current date and time
      };

      // Add review to Firestore
      await addDoc(collection(db, "agent_reviews"), reviewData);
      setReviewFormData({
        userId: "",
        rating: 0,
        comment: "",
      });

      // Refresh reviews
      const reviewsRef = collection(db, "agent_reviews");
      const q = query(reviewsRef, where("agentId", "==", agentId));
      const querySnap = await getDocs(q);
      let reviewsData = [];
      querySnap.forEach((doc) => {
        reviewsData.push({ id: doc.id, data: doc.data() });
      });
      setReviews(reviewsData);
    } catch (error) {
      console.error("Error adding review:", error);
    }
  };

  const indexOfLastListing = currentPage * listingsPerPage;
  const indexOfFirstListing = indexOfLastListing - listingsPerPage;
  const currentListings = agentListings.slice(
    indexOfFirstListing,
    indexOfLastListing
  );
  const [openSharing, setOpenSharing] = useState(false);
  const handleOpenSharing = () => {
    setOpenSharing(true);
  };

  const handleCloseSharing = () => {
    setOpenSharing(false);
  };

  const totalPages = Math.ceil(agentListings.length / listingsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  // Function to handle different sharing methods
  const handleShareMethod = (method) => {
    const listingLink = `https://beytty.com/agent/${agentId}`;
    const message = `Checkout this real estate agent on Beytty: ${listingLink}`;

    switch (method) {
      case "copy":
        navigator.clipboard.writeText(listingLink);
        alert("Link copied to clipboard!");
        break;
      case "whatsapp":
        window.open(
          `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`
        );
        break;
      case "email":
        window.location.href = `mailto:?subject=Check out this listing on beytty.com&body=${encodeURIComponent(
          message
        )}`;
        break;
      case "message":
        // Replace with actual SMS handling logic
        handleSmsShare(message);
        break;
      default:
        break;
    }

    // Close the dialog after handling the share method
    handleCloseSharing();
  };

  // Example function to handle SMS sharing
  const handleSmsShare = (message) => {
    window.location.href = `sms:?body=${encodeURIComponent(message)}`;
  };

  function handleCall() {
    // If the button text is a phone number, initiate the call
    window.location.href = `tel:${agentInfo.phoneNumber}`;
  }

  if (!agentInfo) {
    return <Bar loading={loading} />;
  }

  if (agentInfo.status !== "approved") {
    return <Navigate to="/" />;
  }


  return (
    <div className=" md:flex p-8 max-w-6xl gap-8 mx-auto">
      <Helmet>
        <title>
          Agent Profile - {agentInfo.firstName}
          {agentInfo.lastName} | Beytty
        </title>
        <meta
          name="description"
          content={`Meet ${agentInfo.firstName} ${agentInfo.lastName}, a top real estate agent with expertise in finding the perfect property for you. Discover their listings, reviews, and contact information.`}
        />
        <meta
          name="keywords"
          content={`real estate agent, ${agentInfo.firstName} ${agentInfo.lastName}, agent profile, property expert, Morocco real estate, Beytty, top agent, real estate listings`}
        />
        <meta
          property="og:title"
          content={`Agent Profile - ${agentInfo.firstName} ${agentInfo.lastName} | Beytty`}
        />
        <meta
          property="og:description"
          content={`Meet ${agentInfo.firstName} ${agentInfo.lastName}, a top real estate agent with expertise in finding the perfect property for you. Discover their listings, reviews, and contact information.`}
        />
        <meta property="og:type" content="profile" />
        <meta
          property="og:url"
          content={`https://www.beytty.com/agent/${agentId}`}
        />
        <meta property="og:image" content="/apple-touch-icon.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={`Agent Profile - ${agentInfo.firstName} ${agentInfo.lastName} | Beytty`}
        />
        <meta
          name="twitter:description"
          content={`Meet ${agentInfo.firstName} ${agentInfo.lastName}, a top real estate agent with expertise in finding the perfect property for you. Discover their listings, reviews, and contact information.`}
        />
        <meta name="twitter:image" content="/apple-touch-icon.png" />

        <title>
          Profil de l'Agent - {agentInfo.firstName} {agentInfo.lastName} |
          Beytty
        </title>
        <meta
          name="description"
          content={`Rencontrez ${agentInfo.firstName} ${agentInfo.lastName}, un agent immobilier de premier plan avec une expertise pour trouver la propriété parfaite pour vous. Découvrez ses annonces, avis et coordonnées.`}
        />
        <meta
          name="keywords"
          content={`agent immobilier, ${agentInfo.firstName} ${agentInfo.lastName}, profil d'agent, expert en immobilier, immobilier au Maroc, Beytty, meilleur agent, annonces immobilières`}
        />
        <meta
          property="og:title"
          content={`Profil de l'Agent - ${agentInfo.firstName} ${agentInfo.lastName} | Beytty`}
        />
        <meta
          property="og:description"
          content={`Rencontrez ${agentInfo.firstName} ${agentInfo.lastName}, un agent immobilier de premier plan avec une expertise pour trouver la propriété parfaite pour vous. Découvrez ses annonces, avis et coordonnées.`}
        />
        <meta
          name="twitter:title"
          content={`Profil de l'Agent - ${agentInfo.firstName} ${agentInfo.lastName} | Beytty`}
        />
        <meta
          name="twitter:description"
          content={`Rencontrez ${agentInfo.firstName} ${agentInfo.lastName}, un agent immobilier de premier plan avec une expertise pour trouver la propriété parfaite pour vous. Découvrez ses annonces, avis et coordonnées.`}
        />
      </Helmet>

      {agentInfo && agentInfo.status === "approved" && (
        <>
          <div className="md:w-2/3">
            <div className="flex gap-4 items-center">
              <img
                src={agentInfo.photoURL ? agentInfo.photoURL : "/anonym.png"}
                alt={`${agentInfo.firstName} ${agentInfo.lastName}'s profile`}
                className="h-20 w-20 md:h-28 md:w-28 rounded-full object-cover"
              />{" "}
              <div>
                <h1 className="flex font-semibold text-xl items-center gap-1 md:text-4xl">
                  {`${agentInfo.firstName} ${agentInfo.lastName}`}
                  <RiVerifiedBadgeFill
                    style={{
                      color: "red",
                    }}
                  />
                </h1>
                <h2 className="flex items-center gap-1 text-sm md:text-lg">
                  <p className="text-gray-700 opacity-70">
                    {isAdmin ? t("Official account") : t("verifiedagent")}
                  </p>
                </h2>
              </div>
            </div>
            <div>
              <h2 className="mt-10 md:mt-14 font-semibold md:text-2xl mb-4">
                {t("aboutme")}
              </h2>

              {agentInfo &&
              agentListings &&
              agentListings.filter((listing) => listing.data.status === "sold")
                .length > 0 ? (
                agentListings.filter(
                  (listing) => listing.data.status === "sold"
                ).length > 1 ? (
                  <p className="font-semibold text-sm md:text-md">
                    {
                      agentListings.filter(
                        (listing) => listing.data.status === "sold"
                      ).length
                    }{" "}
                    {t("soldlistings")}
                  </p>
                ) : (
                  <p className="font-semibold text-sm md:text-md">
                    1 {t("soldlisting")}
                  </p>
                )
              ) : (
                <p className="font-semibold text-sm md:text-md "></p>
              )}
              {agentInfo &&
              agentInfo.specialties &&
              agentInfo.specialties.length > 0 ? (
                <p className="font-semibold text-sm md:text-md">
                  {t("specialties")}: {agentInfo.specialties.join(", ")}.
                </p>
              ) : (
                <p className="font-semibold text-sm md:text-md ">
                  {t("specialties")}: --
                </p>
              )}
              <p className="text-sm md:text-md mt-1">{agentInfo.aboutMe}</p>
            </div>
            <div>
              <div className="flex gap-2">
                <button
                  className="w-4/5 md:hidden bg-custom-red  text-white px-4 py-3 mt-10 rounded-xl"
                  onClick={() =>
                    contactFormRef.current.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    })
                  }
                >
                  {t("contact")}
                </button>
                <button
                  className="w-1/5 md:hidden bg-gray-200  border-gray-200 text-black px-4 py-3 mt-10 rounded-xl"
                  onClick={handleOpenSharing}
                >
                  <IoShareOutline className="justify-center text-black items-center w-full text-2xl" />
                </button>
              </div>
              <div className="mt-10 md:mt-14 mb-4 flex align-center items-center">
                <h2 className=" w-full  font-semibold md:text-2xl ">
                  {t("availablelistings")} (
                  {
                    agentListings.filter(
                      (listing) => listing.data.status === "approved"
                    ).length
                  }
                  )
                </h2>
                <div className="items-center text-sm md:text-base w-full justify-end gap-2 flex">
                  <div className="w-2 h-2 rounded-full bg-orange-400"></div>
                  {t("forrent")}

                  <div className="w-2 h-2 rounded-full bg-green-700"></div>
                  {t("forsale")}
                </div>
              </div>
              {agentListings.filter(
                (listing) => listing.data.status === "approved"
              ).length > 0 && (
                <ul className="flex md:gap-10 py-2 md:py-4  font-semibold">
                  <li className="flex w-1/4 text-sm md:text-md">
                    {t("listings")}
                  </li>
                  <li className="flex w-1/4 text-sm md:text-md">Address</li>
                  <li className="w-1/4 text-sm md:text-md">Type</li>
                  <li className="flex w-1/4 text-sm md:text-md">
                    <p className="ml-auto">{t("price")}</p>
                  </li>
                </ul>
              )}
              <div className="w-full bg-gray-200 h-[2px]"></div>
              <ul className="">
                {!loading &&
                agentListings.filter(
                  (listing) => listing.data.status === "approved"
                ).length > 0 ? (
                  currentListings.map((listing) => (
                    <li key={listing.id} className="text-xs sm:text-sm ">
                      <Link
                        to={`/listingdetails/${listing.id}`}
                        className="block"
                      >
                        <div className="flex  md:gap-10 py-2 md:py-4 hover:bg-gray-50 rounded-xl">
                          <div className="flex w-1/4">
                            <img
                              src={
                                listing.data.imgUrls || "/placeholder-image.jpg"
                              }
                              alt="Image of the agent listing"
                              className="h-8 w-16 px-1 md:h-10 md:w-20 object-cover rounded-xl mb-2"
                            />
                          </div>
                          <div className="w-1/4">
                            <p className="flex capitalize text-gray-700 mb-1">
                              {listing.data.address.slice(0, 25)}
                              {listing.data.address.length > 25 && "..."}
                            </p>
                          </div>

                          <div className="w-1/4">
                            <p className="flex capitalize mb-1">
                              {listing.data.listingType}
                            </p>
                          </div>
                          <div className="flex font-semibold w-1/4 ">
                            <p className="ml-auto">
                              {new Intl.NumberFormat().format(
                                listing.data.regularPrice
                              )}
                              DH
                            </p>
                          </div>
                          <div className="flex absolute items-center">
                            <span
                              className="flex justify-center items-center border-2 border-white rounded-full"
                              style={{
                                backgroundColor:
                                  listing.data.type === "sale"
                                    ? "green"
                                    : "orange",
                                color:
                                  listing.data.type === "sale"
                                    ? "green"
                                    : "orange",
                              }}
                            >
                              {listing.data.type === "sale" ? (
                                <div className="text-sm h-2 w-2 rounded-full text-green-500" />
                              ) : (
                                <div className="text-sm h-2 w-2 rounded-full text-orange-400" />
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="w-full bg-gray-200 h-[2px]"></div>
                      </Link>
                    </li>
                  ))
                ) : (
                  <div>{t("nolistingstext")}</div>
                )}
              </ul>
            </div>

            <div className="hidden md:flex font-semibold mt-4 pagination gap-1 justify-center">
              {agentListings.filter(
                (listing) => listing.data.status === "approved"
              ).length > 0 && (
                <button onClick={handlePrevPage} disabled={currentPage === 1}>
                  <GrPrevious />
                </button>
              )}

              {Array.from({ length: totalPages }, (_, index) => {
                // Check if the page number is one of the current, previous, or next three pages
                if (
                  index + 1 === currentPage ||
                  index + 2 === currentPage ||
                  index === currentPage
                ) {
                  return (
                    <button
                      key={index}
                      onClick={() => handlePageChange(index + 1)}
                      style={{
                        backgroundColor:
                          currentPage === index + 1 ? "#ffebeb" : "transparent",
                        border:
                          currentPage === index + 1
                            ? "2px solid red"
                            : "1px solid transparent",
                        color: currentPage === index + 1 ? "black" : "#333",
                        padding: "5px 10px",
                        borderRadius: "50%",
                        cursor: "pointer",
                        width: "40px", // Adjust width as needed for circular buttons
                        height: "40px", // Adjust height as needed for circular buttons
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {index + 1}
                    </button>
                  );
                }
                // Show ellipses (...) for pages not included in the current, previous, or next three pages
                else if (index === 0 || index === totalPages - 1) {
                  return (
                    <span key={index} style={{ padding: "5px 10px" }}>
                      ...
                    </span>
                  );
                }
                return null;
              })}

              {agentListings.length > 0 && (
                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  <GrNext />
                </button>
              )}
            </div>
            <div className="flex md:hidden font-semibold mt-4 pagination gap-1 justify-center">
              {agentListings.filter(
                (listing) => listing.data.status === "approved"
              ).length > 0 && (
                <button onClick={handlePrevPage} disabled={currentPage === 1}>
                  <GrPrevious />
                </button>
              )}

              {Array.from({ length: totalPages }, (_, index) => {
                // Check if the page number is one of the current, previous, or next three pages
                if (
                  index + 1 === currentPage ||
                  index + 2 === currentPage ||
                  index === currentPage
                ) {
                  return (
                    <button
                      key={index}
                      onClick={() => handlePageChange(index + 1)}
                      style={{
                        backgroundColor:
                          currentPage === index + 1 ? "#ffebeb" : "transparent",
                        border:
                          currentPage === index + 1
                            ? "2px solid red"
                            : "1px solid transparent",
                        color: currentPage === index + 1 ? "black" : "#333",
                        padding: "5px 10px",
                        borderRadius: "50%",
                        cursor: "pointer",
                        width: "30px", // Adjust width as needed for circular buttons
                        height: "30px", // Adjust height as needed for circular buttons
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {index + 1}
                    </button>
                  );
                }
                // Show ellipses (...) for pages not included in the current, previous, or next three pages
                else if (index === 0 || index === totalPages - 1) {
                  return (
                    <span key={index} style={{ padding: "5px 10px" }}>
                      ...
                    </span>
                  );
                }
                return null;
              })}

              {agentListings.length > 0 && (
                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  <GrNext />
                </button>
              )}
            </div>

            {agentListings.length > 0 && (
              <p className="mt-6 md:mt-14 font-semibold md:text-2xl mb-4">
                {" "}
                {t("listingsmap")} ({agentListings.length})
              </p>
            )}
            {agentListings.length > 0 && (
              <div className="bg-gray-200 rounded-xl h-[400px] mt-4">
                <ReactMapGL
                  {...viewport}
                  mapStyle="mapbox://styles/mohamedmakdad/clsqz4sct00xn01pf8o9vg38g"
                  mapboxApiAccessToken={
                    process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
                  }
                  onMove={(evt) => setViewport(evt.viewport)}
                >
                  {saleListingsCoordinates.map((listing) => (
                    <Marker
                      key={listing.id}
                      latitude={listing.latitude}
                      longitude={listing.longitude}
                    >
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          borderRadius: "50%",
                          backgroundColor: "red",
                          border: "4px solid white",
                        }}
                      ></div>
                    </Marker>
                  ))}
                  {rentListingsCoordinates.map((listing) => (
                    <Marker
                      key={listing.id}
                      latitude={listing.latitude}
                      longitude={listing.longitude}
                    >
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          borderRadius: "50%",
                          backgroundColor: "purple",
                          border: "4px solid white",
                        }}
                      ></div>
                    </Marker>
                  ))}
                </ReactMapGL>
              </div>
            )}
            {/* Add Review Section */}
            <div className="mt-20 mb-4 space-y-2">
              {/* Display Reviews */}
              <div className="border rounded-xl space-y-2 p-6">
                <h2 className=" sm:text-xl md:text-2xl font-semibold mb-6">
                  {t("reviews")}
                </h2>
                {reviews.length > 0 ? (
                  reviews.slice(0, reviewsToShow).map((review) => (
                    <div
                      key={review.id}
                      className="w-full mb-4 flex flex-col bg-gray-100 rounded-xl p-4 mx-auto "
                    >
                      {auth.currentUser &&
                        review.data.userId === auth.currentUser.uid && (
                          <MdDelete
                            className="ml-auto text-xl text-red-700 cursor-pointer"
                            onClick={() => handleDeleteReview(review.id)}
                          />
                        )}
                      <p className="text-sm md:text-lg font-semibold mb-2">
                        {review.data.firstName}
                      </p>
                      <p className="md:text-xl">
                        <StarRating rating={review.data.rating} />
                      </p>

                      <p className="mt-2 text-sm md:text-base mb-2">
                        {review.data.comment}
                      </p>
                      <p className="text-xs md:text-sm opacity-50">
                        {formatReviewTimestamp(review.data.timestamp)}
                      </p>
                    </div>
                  ))
                ) : (
                  <p>{t("noreviews")}</p>
                )}
                {reviewsToShow < reviews.length && (
                  <button
                    className="text-black hover:bg-gray-100 transition-all duration ease-in-out mt-4 flex gap-2 items-center hover:underline justify-center mx-auto p-2 rounded-full px-4"
                    onClick={showMoreReviews}
                  >
                    {t("showmore")}
                    <FaAngleDown className="mt-1" />
                  </button>
                )}
              </div>
              {!user ? (
                <div className="border rounded-xl space-y-2 p-6">
                  <h2 className="md:text-2xl  mb-4 font-semibold">
                    {t("writeareview")}
                  </h2>
                  <button
                    onClick={() => navigate("/sign-in")}
                    className="bg-custom-red text-white px-4 py-2 rounded"
                  >
                    {t("signin")}
                  </button>
                  {error && <p className="text-red-600">{error}</p>}
                </div>
              ) : !isVerified ? (
                <div className="border rounded-xl space-y-2 p-6">
                  <h22 className="md:text-2xl font-semibold">
                    {t("writeareview")}
                  </h22>
                  <p className="text-sm py-2 md:text-md text-gray-600">
                    {t("verifyemailtoreview")}
                  </p>
                  {emailSent ? (
                    <p className="text-green-600">{t("emailsent")}</p>
                  ) : (
                    <button
                      onClick={handleResendVerification}
                      disabled={sendingVerification}
                      className=" text-red-700 py-2 rounded"
                    >
                      {sendingVerification ? t("sending") : t("resend")}
                    </button>
                  )}
                  {error && <p className="text-red-600">{error}</p>}
                </div>
              ) : (
                <div className="border rounded-xl space-y-2 p-6">
                  <h2 className="md:text-2xl font-semibold">
                    {t("writeareview")}
                  </h2>
                  <form onSubmit={handleReviewSubmit} className="space-y-4">
                    <div>
                      <label htmlFor="rating" className="block">
                        {t("rating")}
                      </label>
                      <div className="flex items-center text-xl">
                        <StarSelector onChange={handleReviewRatingChange} />
                      </div>
                      {ratingError && (
                        <p className="text-red-500 text-sm mb-2">
                          {ratingError}
                        </p>
                      )}
                    </div>
                    <div>
                      <label htmlFor="comment" className="block">
                        {t("comment")}
                      </label>
                      <TextField
                        id="comment"
                        name="comment"
                        value={reviewFormData.comment}
                        onChange={handleReviewInputChange}
                        placeholder="..."
                        fullWidth
                        multiline
                        rows={2} // Adjust the number of rows as needed
                        variant="outlined"
                        margin="normal"
                        inputProps={{ maxLength: 200 }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "gray",
                            },
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused": {
                              boxShadow: "none",
                            },
                            "& input": {
                              boxShadow: "none",
                            },
                            "& textarea": {
                              boxShadow: "none",
                            },
                          },
                        }}
                      />
                      <div className="flex mx-auto justify-end">
                        <Typography variant="caption" color="textSecondary">
                          {reviewFormData.comment.length}/200
                        </Typography>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="bg-custom-red w-full text-white px-4 py-3 rounded-xl"
                    >
                      {t("submitreview")}
                    </button>
                  </form>
                </div>
              )}
            </div>
          </div>
          <div className="md:w-1/3 space-y-2">
            <div className="hidden md:flex w-full items-center px-2 justify-end gap-4 mb-4">
              <p
                className="text-sm underline items-center flex cursor-pointer gap-1"
                onClick={handleOpenSharing}
              >
                <IoShareOutline
                  className="cursor-pointer"
                  onClick={handleOpenSharing}
                />
                {t("share")}
              </p>
            </div>
            <div
              ref={contactFormRef}
              className="border mt-10 md:mt-0 rounded-xl space-y-2 p-6"
            >
              <h2 className="md:text-2xl font-semibold mb-6">
                {t("professionalinfo")}
              </h2>
              <p>
                <strong>Email:</strong> {agentInfo.email}
              </p>
              <p>
                <strong> {t("agency")}:</strong> {agentInfo.agency}
              </p>

              <p>
                <strong> {t("membersince")}:</strong> {memberSince}
              </p>
              {agentInfo &&
              agentInfo.language &&
              agentInfo.language.length > 0 ? (
                <p className="">
                  <strong> {t("languages")}:</strong>{" "}
                  {agentInfo.language.join(", ")}
                </p>
              ) : (
                <p className="">
                  <strong>{t("languages")}:</strong> --
                </p>
              )}
              <p className="pt-4">
                <strong className=" text-red-600"> {t("phonenumber")}:</strong>
              </p>
              <button
                className="bg-gray-100  border-gray-200 justify-center border flex items-center gap-2  text-black w-full px-4 py-3 rounded-xl"
                type="button"
                onClick={handleCall}
              >
                <FaPhone />
                {agentInfo.phoneNumber}
              </button>
            </div>{" "}
            <div
              ref={contactFormRef}
              className="border rounded-xl space-y-2 p-6"
            >
              <h2 className="md:text-2xl font-semibold mb-6">
                {t("contactagent")}
              </h2>
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label htmlFor="name" className="block">
                    {t("name")}
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={contactFormData.name}
                    onChange={handleInputChange}
                    className="border border-gray-200 bg-gray-100 w-full p-2 rounded-xl"
                  />
                  {nameError && (
                    <p className="text-red-500 text-sm mb-2">{nameError}</p>
                  )}
                </div>
                <div>
                  <label htmlFor="phoneNumber" className="block">
                    {t("phonenumber")}
                  </label>
                  <input
                    type="tel"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={contactFormData.phoneNumber}
                    onChange={handleInputChange}
                    className="border border-gray-200 bg-gray-100 w-full p-2 rounded-xl"
                  />
                  {phoneNumberError && (
                    <p className="text-red-500 text-sm mb-2">
                      {phoneNumberError}
                    </p>
                  )}
                </div>
                <div>
                  <label htmlFor="message" className="block">
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={message}
                    onChange={handleInputChange}
                    className="border h-[250px] border-gray-200 bg-gray-100 w-full p-2 rounded-xl"
                  ></textarea>
                  {messageError && (
                    <p className="text-red-500 text-sm mb-2">{messageError}</p>
                  )}
                </div>
                <button
                  type="button"
                  onClick={handleSendEmail}
                  className="bg-custom-red w-full text-white px-4 py-3 rounded-xl"
                >
                  {t("sendemail")}
                </button>
                <button
                  className="bg-gray-100 border border-gray-200 text-black w-full px-4 py-3 rounded-xl"
                  type="button"
                  onClick={handleSendWhatsApp}
                >
                  {t("sendwtp")}
                </button>
              </form>
            </div>
          </div>
          <Dialog
            className="justify-center relative"
            open={openSharing}
            onClose={handleCloseSharing}
          >
            <DialogContent>
              <DialogContentText>
                <div className="bg-white py-4 rounded-xl max-w-md">
                  <h2 className="text-2xl mb-8 text-black">
                    {t("sharethisprofile")}
                  </h2>
                  <div className="mt-8 grid grid-cols-2 text-right grid-rows-1 gap-4 text-md ">
                    {/* Copy Link */}
                    <div
                      className="flex items-center col-span-3 md:col-span-1 row-span-1 text-black py-3 hover:bg-gray-100 px-8 text-right cursor-pointer rounded-xl border-2"
                      onClick={() => handleShareMethod("copy")}
                    >
                      <FaCopy
                        className="text-lg text-stone-700 lg:text-xl mr-2"
                      />
                      {t("copylink")}
                    </div>
                    {/* Whatsapp */}
                    <div
                      className="flex items-center col-span-3 md:col-span-1 row-span-1 text-black py-3 hover:bg-gray-100 px-8 text-right cursor-pointer rounded-xl border-2"
                      onClick={() => handleShareMethod("whatsapp")}
                    >
                      <IoLogoWhatsapp
                        className="text-lg text-green-700 lg:text-xl mr-2"
                      />
                      Whatsapp
                    </div>
                    {/* Email */}
                    <div
                      className="flex items-center col-span-3 md:col-span-1 row-span-1 text-black py-3 hover:bg-gray-100 px-8 text-right cursor-pointer rounded-xl border-2"
                      onClick={() => handleShareMethod("email")}
                    >
                      <MdEmail
                        className="text-lg text-yellow-500 lg:text-xl mr-2"
                      />
                      Email
                    </div>
                    {/* Message */}
                    <div
                      className="flex items-center col-span-3 md:col-span-1 row-span-1 text-black py-3 hover:bg-gray-100 px-8 text-right cursor-pointer rounded-xl border-2"
                      onClick={() => handleShareMethod("message")}
                    >
                      <FaMessage
                        className="text-md text-blue-300 lg:text-lg mr-2"
                      />
                      Message
                    </div>
                  </div>{" "}
                  <div className="absolute top-4 right-4 ">
                    <IoMdClose
                      className="text-xl text-gray-400 hover:text-black cursor-pointer"
                      onClick={handleCloseSharing}
                    />
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </>
      )}
    </div>
  );
}
