import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { IoMenu } from 'react-icons/io5';
import { VscChromeClose } from "react-icons/vsc";
import { db } from '../firebase';
import { doc, getDoc, getDocs, orderBy, query, where } from 'firebase/firestore';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import MoroccanPlaces from "../components/MoroccanPlaces";
import { PiHeartDuotone } from "react-icons/pi";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";
import { GrPrevious } from "react-icons/gr";
import beyttyicon from "../assets/svg/beyttyicon.svg";





export default function SecondHeader({
  setViewport,
  setListingsWithinViewport,
  listings,
  setSelectedLocation,
  selectedLocation
}) {
    const { i18n } = useTranslation();
    const { t } = useTranslation();
     const changeLanguage = (languageCode) => {
       i18n.changeLanguage(languageCode); // Assuming i18n is your translation library
     };
  const [pageState, setPageState] = useState("Sign in");
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isCreateListingPopUpOpen, setCreateListingPopUpOpen] = useState(false);
  const location = useLocation();
  const locations = MoroccanPlaces();
   const [openMenu, setOpenMenu] = useState(null);
   const handleToggle = (menu) => {
     setOpenMenu(openMenu === menu ? null : menu);
   };

  const [isAgentUser, setIsAgentUser] = useState(false);
  const [userStatus, setUserStatus] = useState(null);
  const navigate = useNavigate();
  const navigateToResults = (filterType) => {
    // Use the `navigate` function to go to the Results page
    // Pass the filter type as a query parameter
    navigate("/results", { state: { filterType } });
  };
  const [loading, setLoading] = useState(true);
  const auth = getAuth();

    const [dropdownOpen, setDropdownOpen] = useState({
      buy: false,
      rent: false,
      sell: false,
      agents: false,
      help: false,
      listings: false,
      profile: false,
    });
    const [isMouseInDropdown, setIsMouseInDropdown] = useState(false);
    let timeoutId = null;

    const handleMouseEnter = (menu) => {
      setDropdownOpen((prev) => ({
        ...prev,
        [menu]: true,
      }));
    };

    const handleMouseLeave = (menu) => {
      if (!isMouseInDropdown) {
        timeoutId = setTimeout(() => {
          setDropdownOpen((prev) => ({
            ...prev,
            [menu]: false,
          }));
        }, 300); // Adjust the delay time (in milliseconds) as needed
      }
    };

    const handleDropdownMouseEnter = () => {
      setIsMouseInDropdown(true);
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
    };

    const handleDropdownMouseLeave = (menu) => {
      setIsMouseInDropdown(false);
      timeoutId = setTimeout(() => {
        setDropdownOpen((prev) => ({
          ...prev,
          [menu]: false,
        }));
      }, 300); // Adjust the delay time (in milliseconds) as needed
    };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setPageState("Profile");
      } else {
        // User is not authenticated, reset states
        setPageState("Sign in");
        setIsAgentUser(false);
        setUserStatus(null);
      }

      // Set loading to false after the authentication state settles
      setLoading(false);
    });

    // Unsubscribe from the auth state listener when the component unmounts
    return () => {
      unsubscribe();
    };
  }, [auth]);

  useEffect(() => {
    async function fetchData() {
      try {
        // Check if the user is authenticated
        const user = auth.currentUser;
        if (!user) {
          // Handle the case where the user is not authenticated
          setIsAgentUser(false);
          setUserStatus(null);
          setPageState("Sign in");
          return;
        }

        // Set the page state to 'My Profile' since the user is authenticated
        setPageState("Profile");

        // Check if the user is an agent
        const agentStatus = await isAgent();
        setIsAgentUser(agentStatus);

        // Fetch user status
        const userDocRef = doc(db, "agents", user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          // Assuming the user status is stored as a field named "status"
          const status = userDoc.data().status;
          setUserStatus(status);
        } else {
          // Handle the case where the user document doesn't exist
          setUserStatus(null);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [auth.currentUser]);

  function pathMatchRoute(route) {
    return route === location.pathname;
  }

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };
  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };
  const toggleCreateListingPopUp = () => {
    setCreateListingPopUpOpen(!isCreateListingPopUpOpen);
  };
  const closeCreateListingPopUp = () => {
    setCreateListingPopUpOpen(false);
  };
  const openCreateListingPopUp = () => {
    setCreateListingPopUpOpen(true);
  };

  const isAgent = async () => {
    const agentDocRef = doc(db, "agents", auth.currentUser.uid);
    const agentDoc = await getDoc(agentDocRef);
    return agentDoc.exists();
  };
  
  return (
    <div className="bg-white mt-2 mb-2 md:mt-4 md:mb-4 top-0 z-60">
      <header className="flex justify-between items-center px-4 md:px-8 mx-auto max-w-8xl">
        <div className="flex container mx-auto w-full md:hidden">
          <div className="flex items-center mx-auto justify-between w-full">
            <IoMenu
              className="w-8 h-8 sm:h-10 sm:w-10 mr-4"
              onClick={toggleMobileMenu}
            />
            <a>
              <img
                src={process.env.PUBLIC_URL + "/BeyttyIcon.png"}
                alt="Beytty Logo"
                className="h-9"
                onClick={() => navigate("/")}
              />
            </a>
            <div className="w-4/5 pl-4 py-2">
              <Autocomplete
                className="bg-white flex items-center rounded outline-0 custom-autocomplete"
                options={locations}
                value={selectedLocation}
                getOptionLabel={(option) => option.name}
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#cccccc",
                        },
                        "&:hover fieldset": {
                          borderColor: "#cccccc",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#cccccc",
                        },
                        "&.Mui-focused": {
                          boxShadow: "none",
                        },
                        "& input": {
                          boxShadow: "none",
                        },
                        "& textarea": {
                          boxShadow: "none",
                        },
                        background: "#ffffff",
                      },
                    }}
                    InputLabelProps={{
                      // Add this to style the label
                      style: { lineHeight: "19px" }, // Adjust the lineHeight to vertically center the label
                    }}
                    label={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "14px",
                        }}
                      >
                        {t("searchplaceholder")}
                        <FaSearch
                          style={{ marginLeft: "8px", fontSize: "18px" }}
                        />
                      </div>
                    }
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                      style: {
                        height: "14px",
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      classes: {
                        root: selectedLocation ? "search-selected" : "",
                      },
                    }}
                    onChange={(event, newValue) => {
                      setSelectedLocation(newValue);
                    }}
                  />
                )}
                // Inside the onChange handler for Autocomplete component
                onChange={(event, newValue) => {
                  setSelectedLocation(newValue);
                  // Update the viewport immediately when a new location is selected

                  if (newValue) {
                    setViewport({
                      latitude: newValue.latitude,
                      longitude: newValue.longitude,
                      zoom: 12,
                    });
                  } else {
                    // If the value is cleared, set viewport to default values
                    setListingsWithinViewport(listings);
                    setViewport({
                      latitude: 31.7917, // Default latitude
                      longitude: -7.0926, // Default longitude
                      zoom: 6,
                    });
                  }
                }}
                filterOptions={(options, { inputValue }) => {
                  if (inputValue.trim() === "") {
                    return [
                      {
                        name: "Type your address",
                        latitude: 33.5731,
                        longitude: -7.5898,
                      },
                    ];
                  }

                  const filteredOptions = options.filter((option) =>
                    option.name.toLowerCase().includes(inputValue.toLowerCase())
                  );
                  return filteredOptions.slice(0, 6);
                }}
              />
            </div>
          </div>
        </div>

        <div className="hidden mb-2 justify-center md:flex w-1/3">
          <ul className="md:flex w-full gap-4 md:gap-4 lg:gap-8 list-none">
            <li
              className="cursor-pointer flex items-center gap-1 pt-3 hover:text-red-800"
              onClick={() => navigate("/")}
            >
              <GrPrevious />
              {t("homepage")}
            </li>
            <li
              className={` cursor-pointer pt-3 hover:text-red-800 ${
                dropdownOpen.sell ? "text-red-800" : ""
              }`}
              onMouseEnter={() => handleMouseEnter("sell")}
              onMouseLeave={() => handleMouseLeave("sell")}
              onClick={() => navigate("/sell")}
            >
              {t("sell")}
            </li>
            <li
              className={` cursor-pointer pt-3 hover:text-red-800 ${
                dropdownOpen.agents ? "text-red-800" : ""
              }`}
              onMouseEnter={() => handleMouseEnter("agents")}
              onMouseLeave={() => handleMouseLeave("agents")}
              onClick={() => navigate("/agentlist")}
            >
              Agents
            </li>
          </ul>
        </div>
        <div className="hidden items-center justify-center mx-auto md:flex w-1/3">
          <img
            src={process.env.PUBLIC_URL + "/Logo.png"}
            alt="Beytty Logo"
            className="md:absolute-none opacity-100 h-7 sm:h-8 cursor-pointer"
            onClick={() => navigate("/")}
          />
        </div>
        <div className="hidden md:flex justify-end gap-4 md:gap-4 lg:gap-8  items-center w-1/3">
          {i18n.language !== "en" && (
            <button
              type="button"
              className="cursor-pointer text-xs hover:text-red-800"
              onClick={() => changeLanguage("en")}
              style={{ marginRight: "5px" }} // Add spacing between flag and text
            >
              <span className="flag-icon flag-icon-fr"></span>
            </button>
          )}

          {i18n.language !== "fr" && (
            <button
              type="button"
              className="cursor-pointer text-xs hover:text-red-800"
              onClick={() => changeLanguage("fr")}
              style={{ marginRight: "5px" }} // Add spacing between flag and text
            >
              <span className="flag-icon flag-icon-gb"></span>
            </button>
          )}
          {pageState === "Profile" && (
            <button
              type="submit"
              className="cursor-pointer text-2xl hover:text-red-800 "
              onClick={() => navigate("/savedlistings")}
            >
              <PiHeartDuotone />
            </button>
          )}
          <button
            type="submit"
            className="cursor-pointer hover:text-red-800 "
            onClick={() => navigate("/help")}
          >
            {t("help")}
          </button>
          {pageState === "Sign in" && (
            <>
              <p
                className={`cursor-pointer  hover:text-red-800  ${
                  pathMatchRoute("/sign-in") || pathMatchRoute("/profile")
                    ? " text-black"
                    : " text-black"
                }`}
                onClick={() => navigate("/profile")}
              >
                {t(pageState)}{" "}
              </p>
              <p
                className="cursor-pointer  hover:text-red-800  "
                onClick={() => navigate("/sign-up")}
              >
                {t("join")}
              </p>
            </>
          )}

          {pageState === "Profile" && (
            <button
              type="submit"
              className="cursor-pointer hover:text-red-800 "
              onClick={() => navigate("/mylistings")}
            >
              {t("listings")}
            </button>
          )}

          {pageState === "Profile" && (
            <p
              className={`cursor-pointer  hover:text-red-800  ${
                pathMatchRoute("/sign-in") || pathMatchRoute("/profile")
                  ? " text-black"
                  : " text-black"
              }`}
              onClick={() => navigate("/profile")}
            >
              {t(pageState)}{" "}
            </p>
          )}
        </div>
      </header>

      {dropdownOpen.sell && (
        <div
          className="absolute mx-auto  flex justify-center border-t-[1px] border-b-[1px] py-4 w-full bg-white z-50"
          onMouseEnter={handleDropdownMouseEnter}
          onMouseLeave={() => handleDropdownMouseLeave("sell")}
        >
          <div className="flex  gap-10 w-full px-4">
            <ul className="py-1  ">
              <li className=" font-semibold  px-4 py-2 0">
                {" "}
                {t("sellingguide")}
              </li>
              <li
                className="cursor-pointer px-4 py-2 hover:text-red-800"
                onClick={() => {
                  handleDropdownMouseLeave("sell");
                  navigate("/sell");
                }}
              >
                {t("checkyouroptions")}
              </li>
              <li
                className="flex items-center  cursor-pointer px-4 py-2 hover:text-red-800"
                onClick={() => {
                  handleDropdownMouseLeave("sell");
                  navigate("/beyttylisting");
                }}
              >
                <img
                  src={beyttyicon}
                  alt="Beytty Icon"
                  className="w-4 h-4 mr-1"
                />
                {t("sellquicklywithbeytty")}
              </li>
              <li
                className="cursor-pointer px-4 py-2 hover:text-red-800"
                onClick={() => {
                  handleDropdownMouseLeave("sell");
                  navigate("/agentlist");
                }}
              >
                {t("sellwithaprofessionalagent")}
              </li>
              <li
                className="cursor-pointer px-4 py-2 hover:text-red-800"
                onClick={() => {
                  handleDropdownMouseLeave("sell");
                  navigate("/create-listing");
                }}
              >
                {t("sellityourself")}
              </li>
            </ul>
            <ul className="w-[1px] h-full bg-gray-200"></ul>
            <ul className="py-1 max-w-6xl ">
              <li className="font-semibold  px-4 py-2 "> {t("more")}</li>
              <li className="cursor-pointer px-4 py-2 hover:text-red-800">
                {t("estimateyourhomevalue")}
              </li>
            </ul>
          </div>
        </div>
      )}
      {dropdownOpen.agents && (
        <div
          className="absolute  flex justify-center border-t-[1px] border-b-[1px] py-4 w-full bg-white   z-50"
          onMouseEnter={handleDropdownMouseEnter}
          onMouseLeave={() => handleDropdownMouseLeave("agents")}
        >
          <div className="flex gap-10 w-full  px-4">
            <ul className="py-1  ">
              <li className="font-semibold  px-4 py-2 ">
                {t("lookingforanagent")}
              </li>
              <li
                className="cursor-pointer px-4 py-2 hover:text-red-800"
                onClick={() => {
                  handleDropdownMouseLeave("agents");
                  navigate("/agentlist");
                }}
              >
                {t("listofagents")}
              </li>
              <li
                className="cursor-pointer px-4 py-2 hover:text-red-800"
                onClick={() => {
                  handleDropdownMouseLeave("agents");
                  navigate("/agent/92kMF4IS32XDLbEA4Fqg3mFUW3D3");
                }}
              >
                {t("beyttylistings")}
              </li>
            </ul>
            <ul className="w-[1px] h-full bg-gray-200"></ul>
            <ul className="py-1 max-w-6xl ">
              <li className="font-semibold  px-4 py-2 "> {t("pro")}</li>
              <li
                className="cursor-pointer px-4 py-2 hover:text-red-800"
                onClick={() => {
                  handleDropdownMouseLeave("agents");
                  navigate("/agent-sign-up");
                }}
              >
                {t("createfreeagentaccount")}
              </li>
              <li className="cursor-pointer px-4 py-2 hover:text-red-800">
                {t("agentaedvertising")}
              </li>
            </ul>
          </div>
        </div>
      )}

      {/* Dark Overlay */}
      {isMobileMenuOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm z-50"
          onClick={closeMobileMenu}
        />
      )}

      {/* Mobile Menu */}
      <div
        className={`md:hidden fixed  left-0 -top-0 h-full w-64 z-50 transition-transform ease-in-out duration-300 transform ${
          isMobileMenuOpen ? "translate-x-0" : "translate-x-[-17rem]"
        }`}
      >
        {/* Mobile Menu Content */}
        <div className="bg-white shadow-lg h-full flex flex-col justify-between">
          {/* Logo and Close Icon */}
          <div className="flex justify-between items-center p-4">
            {/* Your Logo */}
            <img
              src={process.env.PUBLIC_URL + "/Logo.png"} // Replace with your logo path
              alt="Beytty Logo"
              className="w-24"
            />

            {/* Close Icon */}
            <VscChromeClose
              className="h-8 w-8 cursor-pointer"
              onClick={closeMobileMenu}
            />
          </div>

          {/* Menu Items */}
          <ul className="flex flex-col space-y-4 p-4">
            <div className="border-t flex-1 after:border-gray-300"></div>
            <li className="flex w-full items-center">
              {" "}
              <p onClick={() => navigateAndCloseMobileMenu("/Sell")}>
                {t("sell")}
              </p>
              <p
                onClick={() => {
                  handleToggle("sell");
                }}
                className="flex w-full pr-2 text-sm justify-end"
              >
                {openMenu === "sell" ? <FaChevronUp /> : <FaChevronDown />}{" "}
              </p>{" "}
            </li>
            {openMenu === "sell" && (
              <div className="space-y-3 opacity-80">
                {/* Add your subtitle or submenu items here */}

                <p
                  className="text-sm"
                  onClick={() => {
                    handleDropdownMouseLeave("sell");
                    navigateAndCloseMobileMenu("/sell");
                  }}
                >
                  {t("checkyouroptions")}
                </p>
                <p
                  className="text-sm"
                  onClick={() => {
                    handleDropdownMouseLeave("sell");
                    navigateAndCloseMobileMenu("/beyttylisting");
                  }}
                >
                  {t("sellquicklywithbeytty")}
                </p>
                <p
                  className="text-sm"
                  onClick={() => {
                    handleDropdownMouseLeave("sell");
                    navigateAndCloseMobileMenu("/agentlist");
                  }}
                >
                  {t("sellwithaprofessionalagent")}
                </p>
                <p
                  className="text-sm"
                  onClick={() => {
                    handleDropdownMouseLeave("sell");
                    navigateAndCloseMobileMenu("/create-listing");
                  }}
                >
                  {t("sellityourself")}
                </p>
                <p className="text-sm">{t("estimateyourhomevalue")}</p>
              </div>
            )}
            <div className="border-t flex-1 after:border-gray-300"></div>
            <li className="flex w-full items-center">
              <p onClick={() => navigateAndCloseMobileMenu("/agentlist")}>
                {t("agents")}
              </p>
              <p
                onClick={() => {
                  handleToggle("agents");
                }}
                className="flex w-full pr-2 text-sm justify-end"
              >
                {openMenu === "agents" ? <FaChevronUp /> : <FaChevronDown />}{" "}
              </p>{" "}
            </li>
            {openMenu === "agents" && (
              <div className="space-y-3 opacity-80">
                {/* Add your subtitle or submenu items here */}
                <p
                  className="text-sm"
                  onClick={() => {
                    handleDropdownMouseLeave("agents");
                    navigateAndCloseMobileMenu("/agentlist");
                  }}
                >
                  {t("listofagents")}
                </p>
                <p
                  className="text-sm"
                  onClick={() => {
                    handleDropdownMouseLeave("agents");
                    navigateAndCloseMobileMenu(
                      "/agent/92kMF4IS32XDLbEA4Fqg3mFUW3D3"
                    );
                  }}
                >
                  {t("beyttylistings")}
                </p>
                <p
                  className="text-sm"
                  onClick={() => {
                    handleDropdownMouseLeave("agents");
                    navigateAndCloseMobileMenu("/agent-sign-up");
                  }}
                >
                  {t("createfreeagentaccount")}
                </p>
                <p className="text-sm">{t("agentaedvertising")}</p>
              </div>
            )}
            <div className="border-t flex-1 after:border-gray-300"></div>
          </ul>
          {/* Last Menu Item */}
          <ul className="flex flex-col items-center mt-auto space-y-4 p-4">
            {i18n.language !== "en" && (
              <button
                type="button"
                className="cursor-pointer text-xs hover:text-red-800"
                onClick={() => changeLanguage("en")}
              >
                <span className="flag-icon flag-icon-fr"></span>
              </button>
            )}

            {i18n.language !== "fr" && (
              <button
                type="button"
                className="cursor-pointer text-xs hover:text-red-800"
                onClick={() => changeLanguage("fr")}
              >
                <span className="flag-icon flag-icon-gb"></span>
              </button>
            )}
            {pageState === "Profile" && (
              <button
                type="submit"
                className="cursor-pointer text-2xl hover:text-red-800 "
                onClick={() => navigateAndCloseMobileMenu("/savedlistings")}
              >
                <PiHeartDuotone />
              </button>
            )}
            <li
              className="py-2  w-full text-center rounded-xl bg-white text-black border-2"
              onClick={() => navigateAndCloseMobileMenu("/help")}
            >
              {t("help")}
            </li>

            {pageState === "Sign in" && (
              <>
                <li
                  className={`py-2 w-full text-center rounded-xl ${
                    pageState === "Sign in"
                      ? "bg-white text-black border-2"
                      : "bg-black text-white border-2"
                  }`}
                  onClick={() => navigateAndCloseMobileMenu("/sign-in")}
                >
                  {t(pageState)}
                </li>
                <li
                  className="py-2 w-full text-center rounded-xl mb-14 bg-custom-red text-white "
                  onClick={() => navigateAndCloseMobileMenu("/sign-up")}
                >
                  {t("join")}
                </li>
              </>
            )}

            {pageState === "Profile" && (
              <li
                className="py-2  w-full text-center rounded-xl bg-white text-black border-2"
                onClick={() => navigateAndCloseMobileMenu("/mylistings")}
              >
                {t("listings")}
              </li>
            )}
            {pageState === "Profile" && (
              <li
                className={`py-2  w-full text-center rounded-xl  ${
                  pathMatchRoute("/sign-in") || pathMatchRoute("/profile")
                    ? " bg-gray-200 text-black border-2"
                    : " bg-gray-200  text-black border-2"
                }`}
                onClick={() => navigateAndCloseMobileMenu("/profile")}
              >
                {t(pageState)}
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
  function navigateAndCloseMobileMenu(route) {
    navigate(route);
    closeMobileMenu();
  }
  function navigateFilterAndCloseMobileMenu(route) {
    navigateToResults(route);
    closeMobileMenu();
  }
}
