import React, { useState, useEffect, useMemo } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { GrNext, GrPrevious } from "react-icons/gr";
import { Link } from "react-router-dom";
import StarRating from "../components/StartRating";
import moment from "moment";
import Spinner from "../components/Spinner";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Bar from "../components/Bar";



export default function AgentList() {
  const [agents, setAgents] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [language, setLanguage] = useState("");
  const [loading, setLoading] = useState(true); // State variable for loading
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  moment.locale(i18n.language);
  const [cities, setCities] = useState(""); // New state for selected city
  const MoroccanCities = useMemo(
    () => [
      "Agadir",
      "Al Hoceima",
      "Azemmour",
      "Beni Mellal",
      "Boujdour",
      "Casablanca",
      "Chefchaouen",
      "Dakhla",
      "El Jadida",
      "Erfoud",
      "Essaouira",
      "Fes",
      "Fnideq",
      "Guelmim",
      "Ifrane",
      "Kénitra",
      "Khouribga",
      "Laayoune",
      "Larache",
      "Marrakech",
      "Meknes",
      "Mohammedia",
      "Nador",
      "Ouarzazate",
      "Oujda",
      "Rabat",
      "Safi",
      "Salé",
      "Tangier",
      "Taza",
      "Tétouan",
      "Tiznit",
    ],
    []
  );
  
   useEffect(() => {
     async function fetchAgents() {
       try {
         const agentsCollection = collection(db, "agents");
         const agentsSnapshot = await getDocs(agentsCollection);

         const agentsData = [];
         for (const doc of agentsSnapshot.docs) {
           const agent = { id: doc.id, ...doc.data() };

           if (agent.status === "approved") {
             // Fetch reviews for the agent
             const reviewsQuery = query(
               collection(db, "agent_reviews"),
               where("agentId", "==", agent.id)
             );
             const reviewsSnapshot = await getDocs(reviewsQuery);
             const reviewsData = reviewsSnapshot.docs.map((reviewDoc) =>
               reviewDoc.data()
             );

             // Calculate number of reviews and average rating
             const numReviews = reviewsData.length;
             const averageRating =
               numReviews > 0
                 ? reviewsData.reduce(
                     (total, review) => total + review.rating,
                     0
                   ) / numReviews
                 : 0;

             // Find the review with the highest rating
             const highestRatedReview = reviewsData.reduce(
               (prev, current) =>
                 prev.rating > current.rating ? prev : current,
               {}
             );

             agent.numReviews = numReviews;
             agent.averageRating = averageRating.toFixed(1);
             agent.highestRatedReviewComment =
               highestRatedReview.comment || "No reviews available";
             agent.highestRatedReviewTimestamp =
               highestRatedReview.timestamp || null;

             // Fetch listings for the agent
             const listingsQuery = query(
               collection(db, "listings"),
               where("userRef", "==", agent.id)
             );
             const listingsSnapshot = await getDocs(listingsQuery);
             const listingsData = listingsSnapshot.docs.map((listingDoc) =>
               listingDoc.data()
             );

             // Count sold listings
             const soldListingsCount = listingsData.filter(
               (listing) => listing.status === "sold"
             ).length;

             agent.soldListingsCount = soldListingsCount;

             agentsData.push(agent);
           }
         }

         // Define weights
         const reviewWeight = 0.3;
         const ratingWeight = 0.5;
         const soldListingsWeight = 0.2;

         // Sort agents based on the weighted combination of metrics
         agentsData.sort((a, b) => {
           const scoreA =
             a.numReviews * reviewWeight +
             a.averageRating * ratingWeight +
             a.soldListingsCount * soldListingsWeight;
           const scoreB =
             b.numReviews * reviewWeight +
             b.averageRating * ratingWeight +
             b.soldListingsCount * soldListingsWeight;
           return scoreB - scoreA;
         });

         setAgents(agentsData);
         setLoading(false); // Set loading state to false when data is fetched
       } catch (error) {
         console.error("Error fetching agents:", error);
       }
     }

     fetchAgents();
   }, []);


  const filteredAgents = useMemo(() => {
    return agents.filter((agent) => {
      const nameMatch = `${agent.firstName} ${agent.lastName}`
        .toLowerCase()
        .includes(searchQuery.toLowerCase());

      const cityMatch =
        cities === "" || (agent.cities && agent.cities.includes(cities));
      const languageMatch =
        language === "" ||
        (agent.language && agent.language.includes(language));

      return nameMatch && cityMatch && languageMatch;
    });
  }, [agents, searchQuery, cities, language]);


   const [currentPage, setCurrentPage] = useState(1);
    const agentsPerPage = 8;
    const indexOfLastAgent = currentPage * agentsPerPage;
    const indexOfFirstAgent = indexOfLastAgent - agentsPerPage;
    const currentAgents = filteredAgents.slice(
      indexOfFirstAgent,
      indexOfLastAgent
    );

    const totalPages = Math.ceil(filteredAgents.length / agentsPerPage);

    const handlePageChange = (page) => {
      setCurrentPage(page);
    };

    const handlePrevPage = () => {
      setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleNextPage = () => {
      setCurrentPage((prevPage) => prevPage + 1);
    };
    
  return (
    <div className="">
      <Helmet>
        <title>Real Estate Agents in Morocco - Find Your Agent</title>
        <meta
          name="description"
          content="Browse our list of trusted real estate agents in Morocco. Find the perfect agent to help you buy, sell, or rent your property."
        />
        <meta
          name="keywords"
          content="real estate, agents, Morocco, property, buy, sell, rent"
        />
        <meta
          property="og:title"
          content="Real Estate Agents in Morocco - Find Your Agent"
        />
        <meta
          property="og:description"
          content="Browse our list of trusted real estate agents in Morocco. Find the perfect agent to help you buy, sell, or rent your property."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.beytty.com/agentlist" />
        <meta property="og:image" content="/apple-touch-icon.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Real Estate Agents in Morocco - Find Your Agent"
        />
        <meta
          name="twitter:description"
          content="Browse our list of trusted real estate agents in Morocco. Find the perfect agent to help you buy, sell, or rent your property."
        />
        <meta name="twitter:image" content="/apple-touch-icon.png" />

        <title>Agents Immobiliers au Maroc - Trouvez Votre Agent</title>
        <meta
          name="description"
          content="Parcourez notre liste d'agents immobiliers de confiance au Maroc. Trouvez l'agent parfait pour vous aider à acheter, vendre ou louer votre propriété."
        />
        <meta
          name="keywords"
          content="immobilier, agents, Maroc, propriété, acheter, vendre, louer"
        />
        <meta
          property="og:title"
          content="Agents Immobiliers au Maroc - Trouvez Votre Agent"
        />
        <meta
          property="og:description"
          content="Parcourez notre liste d'agents immobiliers de confiance au Maroc. Trouvez l'agent parfait pour vous aider à acheter, vendre ou louer votre propriété."
        />
        <meta
          name="twitter:title"
          content="Agents Immobiliers au Maroc - Trouvez Votre Agent"
        />
        <meta
          name="twitter:description"
          content="Parcourez notre liste d'agents immobiliers de confiance au Maroc. Trouvez l'agent parfait pour vous aider à acheter, vendre ou louer votre propriété."
        />
      </Helmet>
      {loading ? (
        <Bar loading={loading} />
      ) : (
        <>
          <div className="px-8 py-4 max-w-6xl mx-auto">
            <h1 className="font-semibold mb-4 mt-4 custom-font text-xl md:text-3xl">
              {t("searchagents")} {cities ? cities : t("morocco")}.
            </h1>
            <div className="sm:flex p-4 rounded-xl sm:p-8 gap-4 bg-gray-100 mb-8">
              {" "}
              <div className="w-full">
                <p className="mb-1 font-semibold">{t("name")}</p>
                <input
                  type="text"
                  placeholder={t("searchbyname")}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full border border-gray-300 rounded-xl"
                />
              </div>
              <div className="mt-4 sm:mt-0 flex w-full gap-4">
                <div className="w-full">
                  <p className="mb-1 font-semibold">{t("city")}</p>
                  <select
                    value={cities}
                    onChange={(e) => setCities(e.target.value)}
                    className="w-full border border-gray-300 rounded-xl"
                  >
                    <option value="">{t("all")}</option>
                    {MoroccanCities.map((city) => (
                      <option key={city} value={city}>
                        {city}
                      </option>
                    ))}
                  </select>
                </div>{" "}
                <div className=" w-full ">
                  <p className="mb-1 font-semibold">{t("language")}</p>
                  <select
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                    className="p-2 w-full border border-gray-300 rounded-xl"
                  >
                    <option value="">{t("all")} </option>
                    <option value="English">{t("english")}</option>
                    <option value="French">{t("french")}</option>
                    <option value="Arabic">{t("arabic")}</option>
                    <option value="Tamazight">{t("tamazight")}</option>
                  </select>
                </div>
              </div>
            </div>

            <div>
              {filteredAgents.length === 0 ? (
                <p className="sm:text-xl text-center mb-4">{t("sorrytext")}</p>
              ) : (
                <ul>
                  {currentAgents.map((agent) => (
                    <li
                      key={agent.id}
                      className="flex rounded-xl  bg-gray-100  mb-4 p-4 sm:p-8 transform transition-all ease-in-out duration-150 "
                    >
                      <img
                        src={agent.photoURL ? agent.photoURL : "/anonym.png"}
                        alt={`Profile of ${agent.firstName} ${agent.lastName}`}
                        className="h-16 w-16 sm:h-20 sm:w-20 mb-2 rounded-full object-cover"
                      />
                      <div className="flex-col px-8 w-full">
                        <Link
                          to={`/agent/${agent.id}`}
                          className="sm:text-xl hover:text-red-700 hover:underline font-semibold"
                        >{`${agent.firstName} ${agent.lastName}`}</Link>
                        <a
                          href={`tel:${agent.phoneNumber}`}
                          className="flex text-sm md:text-lg w-full"
                        >
                          {agent.phoneNumber}
                        </a>
                        <div className="">
                          <div className="md:text-xl">
                            <StarRating
                              rating={parseFloat(agent.averageRating)}
                            />
                          </div>
                        </div>
                        <p className="text-sm sm:text-md font-light opacity-90">
                          {agent.numReviews > 0 ? (
                            <span> {agent.numReviews} </span>
                          ) : (
                            <></>
                          )}
                          {agent.numReviews > 1 ? (
                            t("reviews")
                          ) : agent.numReviews === 1 ? (
                            t("review")
                          ) : (
                            <></>
                          )}
                        </p>
                        <p className="text-sm sm:text-md font-light opacity-90">
                          {agent.soldListingsCount > 0 ? (
                            <span> {agent.soldListingsCount} </span>
                          ) : (
                            <></>
                          )}
                          {agent.soldListingsCount > 1 ? (
                            t("soldlistings")
                          ) : agent.soldListingsCount === 1 ? (
                            t("soldlisting")
                          ) : (
                            <></>
                          )}
                        </p>
                      </div>
                      <div className="hidden md:flex items-center justify-center mx-auto w-full flex-col">
                        {agent.highestRatedReviewTimestamp && (
                          <p className="text-md text-red-700 mb-1 font-semibold">
                            {t("reviewon")}{" "}
                            {moment(
                              agent.highestRatedReviewTimestamp.toDate()
                            ).format("D MMMM YYYY")}
                          </p>
                        )}
                        <p className="text-center">
                          "{agent.highestRatedReviewComment}"
                        </p>
                      </div>
                      <Link
                        to={`/agent/${agent.id}`}
                        className="flex text-2xl items-center md:text-4xl w-full hover:text-red-700 justify-end"
                      >
                        <GrNext />
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className="hidden md:flex mt-4 font-semibold pagination gap-1 justify-center">
        {filteredAgents.length > 0 && (
          <button onClick={handlePrevPage} disabled={currentPage === 1}>
            <GrPrevious />
          </button>
        )}

        {Array.from({ length: totalPages }, (_, index) => {
          // Check if the page number is one of the current, previous, or next three pages
          if (
            index + 1 === currentPage ||
            index + 2 === currentPage ||
            index === currentPage
          ) {
            return (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                style={{
                  backgroundColor:
                    currentPage === index + 1 ? "#ffebeb" : "transparent",
                  border:
                    currentPage === index + 1
                      ? "2px solid red"
                      : "1px solid transparent",
                  color: currentPage === index + 1 ? "black" : "#333",
                  padding: "5px 10px",
                  borderRadius: "50%",
                  cursor: "pointer",
                  width: "40px", // Adjust width as needed for circular buttons
                  height: "40px", // Adjust height as needed for circular buttons
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {index + 1}
              </button>
            );
          }
          // Show ellipses (...) for pages not included in the current, previous, or next three pages
          else if (index === 0 || index === totalPages - 1) {
            return (
              <span key={index} style={{ padding: "5px 10px" }}>
                ...
              </span>
            );
          }
          return null;
        })}

        {filteredAgents.length > 0 && (
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <GrNext />
          </button>
        )}
      </div>
      <div className="flex md:hidden mt-4 text-sm font-semibold gap-1 pagination  justify-center">
        {filteredAgents.length > 0 && (
          <button onClick={handlePrevPage} disabled={currentPage === 1}>
            <GrPrevious />
          </button>
        )}

        {Array.from({ length: totalPages }, (_, index) => {
          // Check if the page number is one of the current, previous, or next three pages
          if (
            index + 1 === currentPage ||
            index + 2 === currentPage ||
            index === currentPage
          ) {
            return (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                style={{
                  backgroundColor:
                    currentPage === index + 1 ? "#ffebeb" : "transparent",
                  border:
                    currentPage === index + 1
                      ? "2px solid red"
                      : "1px solid transparent",
                  color: currentPage === index + 1 ? "black" : "#333",
                  padding: "5px 10px",
                  borderRadius: "50%",
                  cursor: "pointer",
                  width: "30px", // Adjust width as needed for circular buttons
                  height: "30px", // Adjust height as needed for circular buttons
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {index + 1}
              </button>
            );
          }
          // Show ellipses (...) for pages not included in the current, previous, or next three pages
          else if (index === 0 || index === totalPages - 1) {
            return (
              <span key={index} style={{ padding: "5px 10px" }}>
                ...
              </span>
            );
          }
          return null;
        })}

        {filteredAgents.length > 0 && (
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <GrNext />
          </button>
        )}
      </div>
        </>
      )}
      
    </div>
  );
}
