import React from "react";

const MoroccanPlaces = () => {
  const locations = [
    { name: "Casablanca, Morocco", latitude: 33.5731, longitude: -7.5898 },
    { name: "Rabat, Morocco", latitude: 34.0209, longitude: -6.8416 },
    { name: "Marrakech, Morocco", latitude: 31.6295, longitude: -7.9811 },
    { name: "Fes, Morocco", latitude: 34.0181, longitude: -5.0078 },
    { name: "Tangier, Morocco", latitude: 35.7595, longitude: -5.833 },
    { name: "Agadir, Morocco", latitude: 30.421, longitude: -9.5981 },
    { name: "Oujda, Morocco", latitude: 34.6783, longitude: -1.9064 },
    { name: "Kenitra, Morocco", latitude: 34.261, longitude: -6.5802 },
    { name: "Tetouan, Morocco", latitude: 35.5785, longitude: -5.3684 },
    { name: "Temara, Morocco", latitude: 33.9282, longitude: -7.348 },
    { name: "Safi, Morocco", latitude: 32.2968, longitude: -9.2318 },
    { name: "Mohammedia, Morocco", latitude: 33.6874, longitude: -7.3893 },
    { name: "El Jadida, Morocco", latitude: 33.2338, longitude: -8.5018 },
    { name: "Taza, Morocco", latitude: 34.219, longitude: -4.0087 },
    { name: "Nador, Morocco", latitude: 35.1739, longitude: -2.9339 },
    { name: "Settat, Morocco", latitude: 33.0014, longitude: -7.6164 },
    { name: "Berrechid, Morocco", latitude: 33.267, longitude: -7.5861 },
    { name: "Khouribga, Morocco", latitude: 32.8854, longitude: -6.9061 },
    { name: "Beni Mellal, Morocco", latitude: 32.3373, longitude: -6.3629 },
    { name: "Meknes, Morocco", latitude: 33.8935, longitude: -5.5428 },
    { name: "Sale", latitude: 34.0522, longitude: -6.7981 },
    { name: "Ouarzazate, Morocco", latitude: 30.9336, longitude: -6.9372 },
    { name: "Errachidia, Morocco", latitude: 31.9292, longitude: -4.4244 },
    { name: "Guelmim, Morocco", latitude: 28.9861, longitude: -10.0579 },
    { name: "Laayoune, Morocco", latitude: 27.1492, longitude: -13.2006 },
    {
      name: "Ain Chock, Casablanca",
      latitude: 33.533315,
      longitude: -7.585665,
    },
    { name: "Ain Diab, Casablanca", latitude: 33.589101, longitude: -7.674073 },
    { name: "Ain Sebaa, Casablanca", latitude: 33.60027, longitude: -7.535513 },
    {
      name: "Anfa-superieur, Casablanca",
      latitude: 33.586613,
      longitude: -7.670307,
    },
    { name: "Maarif, Casablanca", latitude: 33.567132, longitude: -7.628094 },
    { name: "L'Oasis, Casablanca", latitude: 33.557706, longitude: -7.636552 },
    { name: "Le Polo, Casablanca", latitude: 33.554126, longitude: -7.602351 },
    {
      name: "Californie, Casablanca",
      latitude: 33.542498,
      longitude: -7.630185,
    },
    { name: "CFC, Casablanca", latitude: 33.566401, longitude: -7.66058 },
    { name: "Oulfa, Casablanca", latitude: 33.555421, longitude: -7.680203 },
    { name: "Tamaris, Casablanca", latitude: 33.518644, longitude: -7.825819 },
    {
      name: "Dar Bouazza, Casablanca",
      latitude: 33.524974,
      longitude: -7.824656,
    },
    {
      name: "Roches Noires, Casablanca",
      latitude: 33.607603,
      longitude: -7.563806,
    },
    {
      name: "Sidi Ma'rouf, Casablanca",
      latitude: 33.535259,
      longitude: -7.64563,
    },
    { name: "Médina, Marrakesh", latitude: 31.625308, longitude: -7.988184 },
    { name: "Guéliz, Marrakesh", latitude: 31.633309, longitude: -8.008356 },
    { name: "Hivernage, Marrakesh", latitude: 31.622322, longitude: -8.015118 },
    { name: "Palmerie, Marrakesh", latitude: 31.659964, longitude: -7.96521 },
    { name: "Agdal, Marrakesh", latitude: 31.588562, longitude: -7.995221 },
    { name: "Agdal, Rabat", latitude: 33.993493, longitude: -6.848371 },
    {
      name: "Yacoub El Mansour, Rabat",
      latitude: 33.983205,
      longitude: -6.886922,
    },
    { name: "Hassan, Rabat", latitude: 33.959053, longitude: -6.865865 },
    { name: "Hay Riad, Rabat", latitude: 33.955223, longitude: -6.872871 },
    {
      name: "Quartier Takaddoum, Rabat",
      latitude: 33.983875,
      longitude: -6.817773,
    },
    { name: "Nzaha, Tangier", latitude: 35.76796, longitude: -5.797599 },
    { name: "Bassatine, Tangier", latitude: 35.769354, longitude: -5.829768 },
    { name: "Beni Makada, Tangier", latitude: 35.750114, longitude: -5.816772 },
    { name: "Bouznika, Morocco", latitude: 33.773481, longitude: -7.159424 },
    { name: "Bouskoura, Casablanca", latitude: 33.453503, longitude: -7.633592 },
  ];

  return locations;
};

export default MoroccanPlaces;
