import React, { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import Spinner from "./Spinner";

export default function PhoneNumberGuard() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [phoneNumberExists, setPhoneNumberExists] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (!user.emailVerified) {
          setUser(user);
          setLoading(false);
          return;
        }

        const userDoc = await getDoc(doc(db, "users", user.uid));
        const agentDoc = await getDoc(doc(db, "agents", user.uid));
        const adminDoc = await getDoc(doc(db, "admins", user.uid));

        const userPhoneNumber = userDoc.exists() && userDoc.data().phoneNumber;
        const agentPhoneNumber =
          agentDoc.exists() && agentDoc.data().phoneNumber;
        const adminPhoneNumber =
          adminDoc.exists() && adminDoc.data().phoneNumber;

        if (userPhoneNumber || agentPhoneNumber || adminPhoneNumber) {
          setPhoneNumberExists(true);
        } else {
          setPhoneNumberExists(false);
        }
        setUser(user);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  if (!user) {
    return <Navigate to="/sign-in" />;
  }

  if (user && !user.emailVerified) {
    return <Navigate to="/email-verification" />;
  }

  if (user && user.emailVerified && !phoneNumberExists) {
    return <Navigate to="/profile" />;
  }

  return <Outlet />;
}
