import React, { useState, useEffect } from "react";
import { GrPrevious, GrNext } from "react-icons/gr";
import Moment from "react-moment";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PiHeartBold, PiHeartFill } from "react-icons/pi";
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/fr";


export default function MarkerPopup({ listing, id }) {
  const [isFavorite, setIsFavorite] = useState(false);
  const auth = getAuth();
  const db = getFirestore();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    moment.locale(i18n.language); // Update moment locale when language changes
  }, [i18n.language]);

  useEffect(() => {
    const checkFavoriteStatus = async () => {
      if (auth.currentUser) {
        const userRef = doc(db, "users", auth.currentUser.uid);
        const agentRef = doc(db, "agents", auth.currentUser.uid);
        const adminRef = doc(db, "admins", auth.currentUser.uid);

        const [userDoc, agentDoc, adminDoc] = await Promise.all([
          getDoc(userRef),
          getDoc(agentRef),
          getDoc(adminRef),
        ]);

        if (userDoc.exists() || agentDoc.exists() || adminDoc.exists()) {
          const ref = userDoc.exists()
            ? userRef
            : agentDoc.exists()
            ? agentRef
            : adminDoc;
          const userData = userDoc.exists()
            ? userDoc.data()
            : agentDoc.exists()
            ? agentDoc.data()
            : adminDoc.data();

          if (
            userData.favoriteListings &&
            userData.favoriteListings.includes(id)
          ) {
            setIsFavorite(true);
          }
        } else {
          navigate("/signup"); // Redirect to sign-up page if user is not found in any collection
        }
      }
    };

    checkFavoriteStatus();
  }, [auth.currentUser, db, id, navigate]);

  const toggleFavorite = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (auth.currentUser) {
      const userRef = doc(db, "users", auth.currentUser.uid);
      const agentRef = doc(db, "agents", auth.currentUser.uid);
      const adminRef = doc(db, "admins", auth.currentUser.uid);

      const [userDoc, agentDoc, adminDoc] = await Promise.all([
        getDoc(userRef),
        getDoc(agentRef),
        getDoc(adminRef),
      ]);

      if (userDoc.exists() || agentDoc.exists() || adminDoc.exists()) {
        const ref = userDoc.exists()
          ? userRef
          : agentDoc.exists()
          ? agentRef
          : adminRef;
        const userData = userDoc.exists()
          ? userDoc.data()
          : agentDoc.exists()
          ? agentDoc.data()
          : adminDoc.data();

        if (
          userData.favoriteListings &&
          userData.favoriteListings.includes(id)
        ) {
          await updateDoc(ref, {
            favoriteListings: arrayRemove(id),
          });
          setIsFavorite(false);
        } else {
          await updateDoc(ref, {
            favoriteListings: arrayUnion(id),
          });
          setIsFavorite(true);
        }
      } else {
        navigate("/signup"); // Redirect to sign-up page if user is not found in any collection
      }
    }
  };

  return (
    <div className="flex flex-col relative justify-between items-center overflow-hidden rounded-xl shadow-md bg-white m-2 md:m-1 text-black transition-all duration-300">
      <Link className="contents" to={`/listingdetails/${id}`}>
        <Slider
          className="w-full "
          dots={false}
          arrows={true}
          nextArrow={<GrNext />}
          prevArrow={<GrPrevious />}
          infinite={true}
        >
          {listing.imgUrls.map((imageUrl, index) => (
            <div key={index}>
              <img
                src={imageUrl}
                alt={`Image ${index}`}
                className="h-[170px] w-full object-cover"
              />
            </div>
          ))}
        </Slider>
      </Link>
      <Moment
        locale={i18n.language}
        className="absolute top-2 left-2 items-center justify-center bg-custom-red bg-opacity-50 text-white font-semibold rounded-xl px-2 py-1 shadow-lg  text-xs"
        fromNow
      >
        {listing.timestamp?.toDate()}
      </Moment>
      <div className="absolute top-8 right-2">
        {isFavorite ? (
          <PiHeartFill
            className="text-white hover:text-gray-100 text-2xl transition-all ease-in-out duration-100 cursor-pointer"
            onClick={toggleFavorite}
          />
        ) : (
          <PiHeartBold
            className="text-white hover:opacity-100 opacity-70 bg-opacity-50 text-2xl transition-all ease-in-out duration-100 cursor-pointer"
            onClick={toggleFavorite}
          />
        )}
      </div>
      <div className="w-full p-[8px]">
        <div className="flex items-center">
          <div
            className={`w-3 h-3 ${
              listing.type === "rent" ? "bg-yellow-500" : "bg-green-600"
            } rounded-full mr-2`}
          ></div>
          <p className="flex text-black text-sm opacity-80 capitalize text-center font-light">
            {listing.listingType === "apartment"
              ? t("apartment")
              : listing.listingType === "villa"
              ? t("villa")
              : listing.listingType === "riad"
              ? t("riad")
              : listing.listingType === "land"
              ? t("land")
              : listing.listingType === "office"
              ? t("office")
              : listing.listingType === "commercial"
              ? t("commercial")
              : listing.listingType === "farmhouse"
              ? t("farmhouse")
              : listing.listingType}{" "}
            {listing.type === "rent" ? t("forrent") : t("forsale")}
          </p>
        </div>

        <p className="text-[#1d1d1d] font-semibold text-lg mt-1">
          {listing.offer
            ? `${listing.regularPrice
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} DH`
            : `${listing.regularPrice
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} DH`}
          {listing.type === "rent" && " / month"}
        </p>

        <div className="flex items-center font-semibold space-x-1">
          {listing.listingType != "land" &&
            listing.listingType != "office" &&
            listing.listingType != "commercial" && (
              <div className="flex items-center space-x-1">
                <p className="text-xs">
                  {listing.bedrooms > 1
                    ? `${listing.bedrooms} ${t("Beds")}`
                    : `${t("oneBed")}`}
                </p>
              </div>
            )}
          {listing.listingType != "land" &&
            listing.listingType != "office" &&
            listing.listingType != "commercial" && (
              <div className="flex items-center space-x-1">
                <p className="text-xs">
                  {listing.bathrooms > 1
                    ? `| ${listing.bathrooms} ${t("Baths")} |`
                    : `${t("oneBath")}`}
                </p>
              </div>
            )}
          <div className="flex items-center space-x-1">
            <p className="text-xs">
              {listing.size > 1 ? ` ${listing.size} m²` : "| 1 m²"}
            </p>
          </div>
        </div>
        <div className="flex items-center  space-x-1">
          <p className="font-light opacity-80 text-sm mb-[2px] capitalize text-black truncate max-w-[200px] md:max-w-[300px]">
            {listing.address}
          </p>
        </div>
      </div>
    </div>
  );
}
